import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

const styles = theme => ({
  root: {
    flexGrow: 1,
    maxWidth: "960px",
    margin: "0 auto",
    marginTop: 150,
  },
  success: {
    marginTop: 150,
  },
})

function success(props) {
  const { classes } = props

  {
    setTimeout(function() {
      window.location = "/"
    }, 3000)
  }

  return (
    <Grid container className={classes.root} justify="center" spacing={16}>
      <Grid item xs={6}>
        <Typography variant="h4" align="center" gutterBottom>
          Woohoo! ... success
        </Typography>
        <Typography variant="body1" align="center" gutterBottom>
          Someone will get back to you shortly.
        </Typography>
      </Grid>
    </Grid>
  )
}

success.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default withStyles(styles, { withTheme: true })(success)
